import React, { useEffect, useState, useRef } from "react"
import { Link, graphql } from "gatsby";
import {
  Layout, SEO, CBreadCrumb, LWrap,CInterviewJumbotron, CQuoteBox, CInterviewMedia, CSectTitle, CSchedule, CStars, CLabelCard, CBtnList
} from "../../../components/_index"
import { recruitArea } from "../../../utils/recruit"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter

  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CBreadCrumb data={{
        parent: [
          {
            label: '採用情報',
            path: '/recruit/'
          },
        ],
        current: {
          label: frontmatter?.title.includes('-') ? frontmatter?.title.split('-')[0] : frontmatter?.title
        }
      }} />
      <CInterviewJumbotron data={{
        date: '2022年02月15日',
        subTitle: 'フロントチーム',
        title: '堤 香奈子',
        labels: ['ザ ロイヤルパーク キャンバス 京都二条'],
        img: {
          src: '/assets/images/recruit/article03/kv.png'
        },
        imgSp: {
          src: '/assets/images/recruit/article03/kv__sp.png'
        }
      }} />
      <section className="l_sect u_pt80">
        <LWrap>
          <CQuoteBox>
            <p>2012年にロイヤルパークホテル（日本橋）に入社後、1年間のOJT研修を経てベルに本配属。ベルキャプテンを務め2017年にフロントへ異動。</p>
            <p>その後エグゼクティブラウンジにてコンシェルジュ業務を3年経験し、2021年4月に京都二条開業準備室メンバーに抜擢され、6月の開業以降フロントとして勤務。</p>
          </CQuoteBox>
          <h2 className="c_headingLv2">社員インタビュー</h2>
          <CInterviewMedia data={[
            {
              img: {
                src: '/assets/images/recruit/article03/img_interview.png',
                alt: ''
              },
              title: <>
               <span>ホテル業界を目指したきっかけや</span>
               <span>入社を決めた理由はなんですか？</span>
              </>,
              text: <>
                <p>接客のスペシャリスト＝ホテリエ、という発想を持ったことがきっかけです。接客にはこれが正しい、間違い、というものがなく“一生正解に辿りつかない仕事“に魅力を感じました。<br />また恥ずかしながら飽き性のため、“毎日新しい出会いがあり同じ日が無い”ホテルという場所で働きたいと思ったことも理由の一つです。</p>
                <p>日本橋のロイヤルパークホテルでは学生時代に研修をしていて、厳しいながらも人間関係がとても良く、同じ業界であれば仕事内容はさほど変わらないので、職場環境を重視して就職先として選びました。</p>
              </>,
            },
            {
              img: {
                src: '/assets/images/recruit/article03/img_interview02.png',
                alt: ''
              },
              title: <>
               <span>異動を希望した理由や開業準備について</span>
               <span>教えてください。</span>
              </>,
              text: <>
                <p>人に恵まれた職場を離れることに葛藤はありましたが、尊敬する先輩を見ていたら自分自身ももっとチャレンジをしたくなったこと、その先輩の下で学んだ自分が、新しい環境でどこまで通用し成長するのかお世話になった方に見てほしいという気持ちがありました。またホテルの新規開業に携われるチャンスはいつでもあるわけではないので、この挑戦で仕事の幅を広げ、経験とキャリアを積みたいと感じたからです。</p>
                <p>開業準備中は、フロント業務のオペレーション構築や館内インフォメーションの作成を一から行いつつ、調理研修や試泊会・協力企業の方との打合せなどで目まぐるしい日々が続きましたが、“いい人間関係を築く、そして楽しく働く！” をモットーに全力で取り組み、確かな成長を感じることができました。</p>
              </>,
            },
            {
              img: {
                src: '/assets/images/recruit/article03/img_interview03.png',
                alt: ''
              },
              title: <>
               <span>フルサービス型ホテルの経験は</span>
               <span>今どのように活きていますか？</span>
              </>,
              text: <>
                <p>自由な服装などからフランクなイメージを持たれるキャンバスですが、接客するうえでの自分の信念、10年間フルサービス型で培ったお客様対応やおもてなしの仕方はは変わりません。<br />キャンバスではフロントスタッフがラウンジ業務も兼務していますが、前職場ではコンシェルジュ業務の一環で料飲サービスにもかかわっていましたし、OJT研修でもレストランを回っていたので、その時のノウハウや経験が活きています。</p>
                <p>フロントカウンターのすぐ横にラウンジがあることでお客様との距離が近く、関わる時間を長く取れることも働く楽しさの一つになっています。</p>
              </>,
            },
            {
              img: {
                src: '/assets/images/recruit/article03/img_interview04.png',
                alt: ''
              },
              title: <>
               <span>堤さんが考える”良い加減”のサービスとは</span>
               <span>どのようなものですか？</span>
              </>,
              text: <>
                <p>視野を広く持つことです。人の感覚やお客様の求めるものは十人十色、良い加減もお客様それぞれです。例え同じお客様でも、その日の体調や出来事によって受けたい接客はきっと変わってくるはず。自分の固定観念や経験上の概念に縛られないよう、お客様を良く観ることが大切だと思います。 </p>
                <p>目線の動きや声のトーンからお客様の気持ちを想像して、自分の目の前にいらっしゃるお客様に”心地良い”と感じていただけるように仕事に取り組んでいます。</p>
              </>,
            },
          ]} />
        </LWrap>
      </section>
      <section className="l_sect u_pb0_pc u_bgGray">
        <LWrap>
          <CSectTitle title={{ ja: "一日のスケジュール", en: "DAILY SCHEDULE" }} />
          <CSchedule
            img={{
              src: '/assets/images/recruit/article03/img_schedule.png',
              alt: ''
            }}
            content={[
              {
                time: '16:00',
                title: <>出勤</>,
                text: <>早番者からの引継ぎ</>
              },
              {
                time: '16:30',
                title: <>チェックイン業務/ラウンジスタンバイ</>,
              },
              {
                time: '18:00',
                title: <>ラウンジバータイムスタート</>,
              },
              {
                time: '19:00',
                title: <>休憩</>,
              },
              {
                time: '20:00',
                title: <>チェックイン業務</>,
                text: <>翌日準備、ラウンジ接客</>
              },
              {
                time: '0:00',
                title: <>キャッシャー締め、売上確認、仮眠</>,
              },
              {
                time: '2:00',
                title: <>大浴場点検、朝食スタンバイ、<br />
                口コミ返信等担当業務</>,
              },
              {
                time: '6:30',
                title: <>ラウンジ朝食スタート</>,
                text: <>朝食のご案内、チェックアウト業務</>
              },
              {
                time: '8:30',
                title: <>早番者への引継ぎ</>,
              },
              {
                time: '9:00',
                title: <>退勤</>,
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect">
        <LWrap>
          <CSectTitle title={{ ja: <>今ある力と<br className="u_sp" />将来へ向けた想い</>, en: "My 6STARS" }} />
          <CStars
            stars={[
              {
                img: {
                  src: '/assets/images/recruit/img_stars_thinking.svg',
                  alt: '人を想う力'
                },
              },
              {
                img: {
                  src: '/assets/images/recruit/img_stars_learning.svg',
                  alt: '学ぶ力'
                }
              },
              {
                img: {
                  src: '/assets/images/recruit/img_stars_work.svg',
                  alt: '共に働く力'
                },
                active: true
              },
              {
                img: {
                  src: '/assets/images/recruit/img_stars_business.svg',
                  alt: 'ビジネスする力'
                },
                active: true
              },
              {
                img: {
                  src: '/assets/images/recruit/img_stars_think.svg',
                  alt: '考え抜く力'
                }
              },
              {
                img: {
                  src: '/assets/images/recruit/img_stars_future.svg',
                  alt: '未来をつくる力'
                },
              },
            ]}
            now={{
              title: <>共に働く力</>,
              text: <>現在の職場は多くのアルバイトスタッフの活躍に支えられています。社員と違い毎日のようには出勤しないアルバイトスタッフに、どのような引継ぎを残せば支障なく業務を行えるのか、日々変わる状況や新しいキャンペーンの情報をどのようにしたら少ない時間で把握してもらえるかを考えながら引継ぎ帳やマニュアルの作成をしています。作ったマニュアルで京都エリアのグループホテル共通で使えそうなものについては、積極的に共有することも心掛けています。</>
            }}
            future={{
              title: <>ビジネスする力</>,
              text: <>今までは現場の人間として目の前にいらしたお客様のご満足を第一に考えており、数字を意識した仕事をすることは多くありませんでした。今後は、どうしたらホテルの知名度が上がりお客様がもっと来てくださるようになるのか、どうしたら売上が伸びるのか、ホテル全体のことをもっと把握できるようになりたいと考えています。</>
            }}
          />
        </LWrap>
      </section>
      <section className="l_sect u_bgGray">
        <LWrap>
          <CSectTitle title={{
            ja: '他の社員の声',
            en: 'OTHER STAFF INTERVIEW'
          }} />
          <CLabelCard align="center" data={[
            {
              img: {
                src: '/assets/images/recruit/img_staff.png',
                alt: ''
              },
              link: {
                href: '/recruit/article01/'
              },
              labels: ['仙台ロイヤルパークホテル'],
              title: {
                dataMain: '柴 朋也',
                sub: <>料飲</>
              },
            },
            {
              img: {
                src: '/assets/images/recruit/img_staff03.png',
                alt: ''
              },
              link: {
                href: '/recruit/article02/'
              },
              labels: ['大阪・神戸エリア'],
              title: {
                dataMain: '橋本 由衣',
                sub: <>管理課</>
              },
            },
          ]} />
          <CBtnList data={[
            {
              iconBefore: true,
              label: '採用情報',
              link: {
                href: '/recruit/',
              },
              icon: "arrowReverse"
            }
          ]} />
        </LWrap>
      </section>
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`